<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card class="primary--border" outlined>
          <v-card-title class="title">
            Guardians
            <v-spacer />
            <v-spacer /><v-spacer /><v-spacer /><v-spacer /><v-spacer /><v-spacer /><v-spacer /><v-spacer /><v-spacer /><v-spacer /><v-spacer /><v-spacer /><v-spacer /><v-spacer /><v-spacer /><v-spacer />
            <add-button
              permission="guardian-create"
              @action="
                (form.dialog = true),
                  (form.roleId = guardianRole.id),
                  (same_address = false),
                  (updateState = false),
                  $refs.form ? $refs.form.reset() : ''
              "
            >
              New Guardian
            </add-button>
            <v-spacer />
            <v-download-column-select
              v-if="
                $auth.isAdmin() || $auth.loggedInRole().slug === 'administrator'
              "
              :showPDF="false"
              :downloadColumns="dynamicColumns"
              @onDownload="downloadGuardian"
            >
              DOWNLOAD Guardian RECORD
            </v-download-column-select>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title mb-0 pb-0">
              <v-flex sm4>
                <v-text-field
                  hint="Atleast 3 characters"
                  outlined
                  dense
                  v-model="search"
                  color="primary"
                  class="pa-0"
                  label="Search by Name, Email or Phone"
                  append-icon="search"
                  />
                  <!-- @input="searchMember" -->
              </v-flex>
              <v-flex sm2>
                <v-select
                  v-model="selected_no_of_children"
                  :items="children_no"
                  label="No of Childern"
                  dense
                  outlined
                ></v-select>
              </v-flex>
            </v-card-title>
          </v-card>
          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">
                  <v-list-item>
                    <v-list-item-avatar>
                      <img :src="item.profile_image" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <small>
                          <strong>{{ item.full_name }}</strong>
                        </small>
                      </v-list-item-title>

                      <v-list-item-subtitle class="grey--text text--lighten-1">
                        <!--<v-tooltip bottom>-->
                        <!--<template>-->
                        <!--</template>-->
                        <!--<span>Send SMS</span>-->
                        <!--</v-tooltip>-->
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </td>

                <td class="text-xs-left">
                  <v-icon small color="primary">email</v-icon>
                  {{ item.email || "N/A" }} <br />
                  <v-icon color="warning" small>phone</v-icon>
                  <strong>{{ item.primary_mobile || "N/A" }}</strong>
                </td>
                <td class="text-xs-left">
                  {{ item.join_date_format }} <br />
                  <small style="color: #888"> {{ item.created_at }}</small>
                </td>
                <td>
                  <template v-for="(child, key) in item.children">
                    <router-link :to="'/student/' + child.id" :key="key"
                      ><v-chip
                        style="cursor: pointer"
                        small
                        label
                        class="mr-1 mb-1 mt-1"
                        >{{ child.name }}</v-chip
                      ></router-link
                    >&nbsp;
                    <!--<small :key="ri" v-if="ri<3">-->
                    <!--{{child}}<span v-if="ri<(2)">,</span>-->
                    <!--</small>-->
                  </template>
                </td>
                <td class="text-right">
                  <router-link
                    :to="'/guardian/' + item.id"
                    style="text-decoration: none"
                  >
                    <view-button permission="guardian-read" />
                  </router-link>
                  <edit-button
                    :onCurrentBatch="false"
                    permission="guardian-update"
                    @agree="
                      (form.roleId = ''),
                        fetchRoles(),
                        update(item),
                        item.profile_image
                          ? (cropperImg.cropImg = item.profile_image)
                          : ''
                    "
                  />
                  <delete-button
                    permission="guardian-delete"
                    @agree="form.delete(item.id)"
                  />
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>

      <v-dialog
        @keydown.esc="form.reset(), resetForm(), (form.dialog = false)"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
        v-model="form.dialog"
        persistent
        max-width="1000px"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn
              icon
              dark
              @click="
                (form.dialog = false),
                  form.reset(),
                  resetForm(),
                  resetImagePicker()
              "
            >
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>Add/Update Guardian</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="validate">Save</v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text class="pb-1" style="padding: 0">
            <v-form ref="staffInfoForm" v-model="valid" :lazy-validation="lazy">
              <v-timeline class="user_add_form" align-top dense>
                <v-timeline-item color="first-f" small v-if="updateState">
                  <v-layout pt-3>
                    <v-flex>
                      <strong>Role</strong>
                      <div class="caption">Select type of staff.</div>
                      <v-flex>
                        <v-combobox
                          outlined
                          dense
                          multiple
                          v-model="selectedRole"
                          :items="roles"
                          :error-messages="form.errors.get('roleId')"
                          item-text="name"
                          chips
                          icon="account_circle"
                          label="Select role."
                          :rules="[(v) => !!v || 'Role Field is required']"
                        >
                          <template v-slot:selection="role">
                            <v-chip
                              :input-value="role.selected"
                              color="primary"
                              :key="JSON.stringify(selectedRole.name)"
                              class="v-chip--select-multi"
                            >
                              <v-avatar>
                                <v-icon>assignment_ind</v-icon>
                              </v-avatar>
                              {{ role.item.name }}
                            </v-chip>
                          </template>
                        </v-combobox>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>

                <v-timeline-item color="second-s" small>
                  <v-layout wrap pt-3>
                    <v-flex>
                      <strong>General Info.</strong>
                      <div class="caption">Name, Email etc.</div>
                      <v-flex pt-3 xs11>
                        <v-container fluid style="padding: 0" grid-list-md>
                          <v-layout row wrap>
                            <v-flex sm4 xs12>
                              <v-text-field
                                :disabled="!selectedRole.length && !form.roleId"
                                outlined
                                dense
                                autocomplete="off"
                                label="First Name*"
                                required
                                class="pa-0"
                                v-model="form.fname"
                                name="name"
                                :error-messages="form.errors.get('fname')"
                                :rules="[
                                  (v) => !!v || 'First Name Field is required',
                                ]"
                              />
                            </v-flex>

                            <v-flex sm4 xs12>
                              <v-text-field
                                outlined
                                dense
                                autocomplete="off"
                                label="Middle Name"
                                :disabled="!selectedRole.length && !form.roleId"
                                required
                                class="pa-0"
                                v-model="form.mname"
                                name="rank"
                                :error-messages="form.errors.get('mname')"
                              />
                            </v-flex>

                            <v-flex sm4 xs12>
                              <v-text-field
                                outlined
                                dense
                                autocomplete="off"
                                label="Last Name*"
                                required
                                class="pa-0"
                                v-model="form.lname"
                                :disabled="!selectedRole.length && !form.roleId"
                                name="rank"
                                :error-messages="form.errors.get('lname')"
                                :rules="[
                                  (v) => !!v || 'Last Field is required',
                                ]"
                              />
                            </v-flex>

                            <v-flex sm6 xs12>
                              <v-text-field
                                prepend-inner-icon="email"
                                outlined
                                dense
                                autocomplete="off"
                                label="Email"
                                required
                                class="pa-0"
                                :rules="emailRule"
                                :disabled="!selectedRole.length && !form.roleId"
                                v-model="form.email"
                                name="rank"
                                :error-messages="form.errors.get('email')"
                              />
                            </v-flex>

                            <v-flex sm6 xs12>
                              <v-text-field
                                outlined
                                dense
                                autocomplete="new-password"
                                required
                                v-model="form.password"
                                :append-icon="
                                  p_show ? 'visibility' : 'visibility_off'
                                "
                                :disabled="!selectedRole.length && !form.roleId"
                                :type="p_show ? 'text' : 'password'"
                                :rules="!updateState ? passwordRule : []"
                                name="password"
                                label="Password"
                                @click:append="p_show = !p_show"
                              />
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>

                <v-timeline-item color="third-t" small>
                  <v-layout pt-3>
                    <v-flex>
                      <strong>Address</strong>
                      <div class="caption">Permanent/Current</div>
                      <v-flex pt-3 xs11>
                        <v-container fluid style="padding: 0" grid-list-md>
                          <v-layout row wrap>
                            <v-flex xs12 sm2>
                              <v-autocomplete
                                outlined
                                dense
                                :disabled="!selectedRole.length && !form.roleId"
                                :items="states"
                                v-model="
                                  staff.addressInfo.data.permanent_state_id
                                "
                                label="Province/State"
                                :rules="[
                                  (v) =>
                                    !!v || 'Permanent State Field is required',
                                ]"
                                data-vv-name="select"
                                required
                              />
                            </v-flex>
                            <v-flex xs12 sm3>
                              <v-autocomplete
                                outlined
                                dense
                                :disabled="
                                  !staff.addressInfo.data.permanent_state_id
                                "
                                :items="permanent_districts"
                                v-model="
                                  staff.addressInfo.data.permanent_district_id
                                "
                                label="District"
                                :rules="[
                                  (v) => !!v || 'District Field is required',
                                ]"
                                data-vv-name="select"
                                required
                              />
                            </v-flex>

                            <v-flex xs12 sm3>
                              <v-autocomplete
                                outlined
                                dense
                                :disabled="
                                  !staff.addressInfo.data.permanent_district_id
                                "
                                :items="permanent_cities"
                                :rules="[
                                  (v) =>
                                    !!v || 'Municipality Field is required',
                                ]"
                                v-model="
                                  staff.addressInfo.data.permanent_city_id
                                "
                                label="Municipality/City"
                                data-vv-name="select"
                                required
                              />
                            </v-flex>
                            <v-flex xs12 sm1>
                              <v-autocomplete
                                outlined
                                dense
                                :disabled="
                                  !staff.addressInfo.data.permanent_city_id
                                "
                                :items="ward_count_permanent"
                                :rules="[
                                  (v) => !!v || 'Ward No Field is required',
                                ]"
                                v-model="
                                  staff.addressInfo.data.permanent_ward_no
                                "
                                label="Ward"
                                data-vv-name="select"
                                required
                              />
                            </v-flex>
                            <v-flex xs12 sm3 md3>
                              <v-text-field
                                outlined
                                dense
                                label="Street/Tole"
                                :disabled="
                                  !staff.addressInfo.data.permanent_city_id
                                "
                                v-model="
                                  staff.addressInfo.data.permanent_address_line
                                "
                                :rules="[
                                  (v) => !!v || 'Street/Tole Field is required',
                                ]"
                              />
                            </v-flex>
                            <v-flex pt-0 pb-0 xs12>
                              <v-checkbox
                                label="Mark current address same as permanent"
                                v-model="same_address"
                                :disabled="
                                  !staff.addressInfo.data
                                    .permanent_address_line ||
                                  !staff.addressInfo.data.permanent_city_id
                                "
                              />
                            </v-flex>
                            <v-flex xs12 sm2>
                              <v-autocomplete
                                outlined
                                dense
                                :disabled="!selectedRole.length && !form.roleId"
                                :items="states"
                                v-model="
                                  staff.addressInfo.data.temporary_state_id
                                "
                                label="Province/State"
                                :rules="[
                                  (v) =>
                                    !!v || 'Province/State Field is required',
                                ]"
                                data-vv-name="select"
                                required
                              />
                            </v-flex>
                            <v-flex xs12 sm3>
                              <v-autocomplete
                                outlined
                                dense
                                :disabled="
                                  !staff.addressInfo.data.temporary_state_id
                                "
                                :items="temporary_districts"
                                :rules="[
                                  (v) => !!v || 'District Field is required',
                                ]"
                                v-model="
                                  staff.addressInfo.data.temporary_district_id
                                "
                                label="District"
                                data-vv-name="select"
                                required
                              />
                            </v-flex>
                            <v-flex xs12 sm3>
                              <v-autocomplete
                                outlined
                                dense
                                :disabled="
                                  !staff.addressInfo.data.temporary_district_id
                                "
                                :items="temporary_cities"
                                :rules="[
                                  (v) =>
                                    !!v ||
                                    'Municipality/City Field is required',
                                ]"
                                v-model="
                                  staff.addressInfo.data.temporary_city_id
                                "
                                label="Municipality/City"
                                data-vv-name="select"
                                required
                              />
                            </v-flex>

                            <v-flex xs12 sm1>
                              <v-autocomplete
                                outlined
                                dense
                                :disabled="
                                  !staff.addressInfo.data.temporary_city_id
                                "
                                :items="ward_count_temporary"
                                :rules="[
                                  (v) => !!v || 'Ward Field is required',
                                ]"
                                v-model="
                                  staff.addressInfo.data.temporary_ward_no
                                "
                                label="Ward"
                                data-vv-name="select"
                                required
                              />
                            </v-flex>
                            <v-flex xs12 sm3 md3>
                              <v-text-field
                                outlined
                                dense
                                label="Street/Tole"
                                :disabled="
                                  !staff.addressInfo.data.temporary_city_id
                                "
                                v-model="
                                  staff.addressInfo.data.temporary_address_line
                                "
                                :rules="[
                                  (v) => !!v || 'Street/Tole Field is required',
                                ]"
                              />
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>

                <v-timeline-item color="fourth-f" small>
                  <v-layout pt-3>
                    <v-flex>
                      <strong>Other Information</strong>
                      <div class="caption">Phone, Occupation etc.</div>
                      <v-flex pt-3 xs11>
                        <v-container fluid style="padding: 0" grid-list-md>
                          <v-layout row wrap>
                            <v-flex sm3 xs12>
                              <v-calendar-field
                                :margin="0"
                                id-val="dob"
                                v-model="form.dob"
                                label="Date of Birth"
                              >
                              </v-calendar-field>
                            </v-flex>

                            <v-flex sm5 xs12>
                              <v-autocomplete
                                :items="occupations"
                                outlined
                                dense
                                :disabled="!selectedRole.length && !form.roleId"
                                v-model="form.occupation"
                                :error-messages="form.errors.get('occupation')"
                                label="Occupation"
                                prepend-inner-icon="work"
                              ></v-autocomplete>
                            </v-flex>

                            <v-flex sm2 xs12>
                              <v-autocomplete
                                outlined
                                dense
                                :disabled="!selectedRole.length && !form.roleId"
                                v-model="form.gender"
                                :error-messages="form.errors.get('gender')"
                                prepend-inner-icon="face"
                                :items="gender"
                                :rules="[
                                  (v) => !!v || 'Gender Field is required',
                                ]"
                                label="Gender"
                                data-vv-name="select"
                                required
                              />
                            </v-flex>

                            <v-flex sm2 xs12>
                              <v-autocomplete
                                outlined
                                dense
                                :disabled="!selectedRole.length && !form.roleId"
                                v-model="form.blood_group"
                                :error-messages="form.errors.get('blood_group')"
                                prepend-inner-icon="add_location"
                                :items="blood"
                                label="Blood group"
                                data-vv-name="select"
                                required
                              />
                            </v-flex>
                            <v-flex sm3 xs12>
                              <v-text-field
                                outlined
                                dense
                                :disabled="!selectedRole.length && !form.roleId"
                                v-model="form.residence"
                                :error-messages="form.errors.get('residence')"
                                v-mask="'###############'"
                                label="Phone"
                                prepend-inner-icon="perm_phone_msg"
                              />
                            </v-flex>
                            <v-flex sm3 xs12>
                              <v-text-field
                                outlined
                                dense
                                :disabled="!selectedRole.length && !form.roleId"
                                v-model="form.primary_mobile"
                                :error-messages="
                                  form.errors.get('primary_mobile')
                                "
                                :rules="staff.validation.primaryMobileRule"
                                label="Mobile"
                                mask="##########"
                                hint="10 digits"
                                counter
                                prepend-inner-icon="mobile_screen_share"
                              ></v-text-field>
                            </v-flex>
                            <v-flex sm3 xs12>
                              <v-autocomplete
                                outlined
                                dense
                                :disabled="!selectedRole.length && !form.roleId"
                                v-model="form.religion"
                                :error-messages="form.errors.get('religion')"
                                prepend-inner-icon="account_balance"
                                :items="religions"
                                label="Religion"
                                data-vv-name="select"
                                required
                              />
                            </v-flex>

                            <v-flex sm3 xs12>
                              <v-autocomplete
                                outlined
                                dense
                                prepend-inner-icon="place"
                                :disabled="!selectedRole.length && !form.roleId"
                                v-model="form.ethnicity"
                                :error-messages="form.errors.get('ethnicity')"
                                :items="ethnicities"
                                label="Ethnicity"
                                data-vv-name="select"
                                required
                              />
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>

                <v-timeline-item color="fifth-fi" small>
                  <v-layout pt-3>
                    <v-flex>
                      <strong>Upload Picture</strong>
                      <div class="caption">Your profile picture.</div>
                      <v-flex pt-3 xs11>
                        <v-container fluid style="padding: 0" grid-list-md>
                          <v-layout row wrap>
                            <v-flex xs6>
                              <image-picker
                                :disabled="!selectedRole.length && !form.roleId"
                                ui-type="text"
                                label="Select Profile Image"
                                ref="picker"
                                :height="200"
                                :width="200"
                                :ratio="1"
                                @result="cropImage"
                              ></image-picker>

                              <!--<v-text-field-->
                              <!--:disabled="!selectedRole.length && !form.roleId"-->
                              <!--outlined dense label="Select Photo"-->
                              <!--v-model="cropperImg.name"-->
                              <!--prepend-inner-icon="add_a_photo"-->
                              <!--@click="onPickFile"-->
                              <!--required-->
                              <!--/>-->
                              <!--<input-->
                              <!--type="file"-->
                              <!--style="display: none"-->
                              <!--ref="image"-->
                              <!--accept="image/*"-->
                              <!--@change="onFilePicked">-->
                            </v-flex>
                            <v-flex
                              xs3
                              class="text-xs-center text-sm-center text-md-center text-lg-center"
                              v-if="cropperImg.cropImg"
                            >
                              <v-card>
                                <v-card-text>
                                  <img :src="cropperImg.cropImg" height="150" />
                                  <v-btn
                                    v-if="!updateState"
                                    fab
                                    small
                                    bottom
                                    right
                                    absolute
                                    @click.native.stop="resetImagePicker"
                                    style="z-index: 1"
                                    color="red white--text"
                                  >
                                    <v-icon dark>close</v-icon>
                                  </v-btn>
                                </v-card-text>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>
              </v-timeline>
            </v-form>
          </v-card-text>
        </v-card>

        <v-dialog v-model="cropperImg.cropDialog" persistent max-width="600">
          <v-card>
            <vue-cropper
              ref="cropper"
              :guides="true"
              :view-mode="2"
              drag-mode="crop"
              :auto-crop-area="0.5"
              :min-container-width="100"
              :min-container-height="100"
              :background="false"
              :min-crop-box-width="200"
              :max-crop-box-width="200"
              :min-crop-box-height="200"
              :max-crop-box-height="200"
              :aspect-ratio="1"
              :rotatable="true"
              :src="cropperImg.imgSrc"
              alt="Source Image"
              :img-style="{ width: '500px', height: '500px' }"
            >
            </vue-cropper>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="cropperImg.cropDialog = false"
                >Cancel</v-btn
              >
              <v-btn color="green darken-1" text @click="cropImage">Crop</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import Form from "@/library/Form";
import { getPropertyFromArrayObject,redirectDownloadUrl } from "@/library/helpers";
import Mixins from "@/library/Mixins";

import statesData from "@/assets/json/states.json";
import districtDataData from "@/assets/json/districts.json";
import cityData from "@/assets/json/cities.json";
import VueCropper from "vue-cropperjs";
import ImageCompressor from "image-compressor.js";
import { validateDate } from "../../../../../library/helpers";
import "cropperjs/dist/cropper.css";
// import { passDataToExternalDomain } from "@/library/helpers";
let typingTimer;
let doneTypingInterval = 600;

const NepaliDate = require("nepali-date-js");
const dt = new NepaliDate();
const year = parseInt(dt.getYear());

export default {
  mixins: [Mixins],
  components: { VueCropper },
  data: () => ({
    valid: true,
    lazy: false,
    cropDialog: false,
    imgSrc: "",
    cropImg: "",
    uploadCropped: {},
    cropperImg: {
      cropDialog: false,
      imgSrc: "",
      cropImg: "",
      uploadCropped: {},
      name: null,
    },
    dateValidation: [(v) => validateDate(v) || "Invalid Date Format"],
    emailRule: [(v) => !v || /.+@.+/.test(v) || "E-mail must be valid"],
    passwordRule: [
      (v) => !!v || "Password is required",
      (v) => !(v && v.length < 8) || "Password must be atleast  8 characters",
    ],
    form: new Form(
      {
        dialog: false,
        fname: "",
        mname: "",
        lname: "",
        primary_mobile: "",
        email: "",
        password: "",
        pcity_id: "",
        pstreet: "",
        tcity_id: "",
        tstreet: "",
        pstate_id: "",
        pdistrict_id: "",
        tstate_id: "",
        tdistrict_id: "",
        secondary_mobile: "",
        residence: "",
        gender: "",
        dob: "",
        blood_group: "",
        name: "",
        occupation: "",
        religion: "",
        ethnicity: "",
        profile_image: "",
        pward_no: "",
        roleId: "",
        tward_no: "",
        image: {},
      },
      "/api/user"
    ),
    imagePicker: {
      name: null,
      image: null,
      url: null,
    },
    staff: {
      validation: {
        valid: false,
        fnameRule: [(v) => !!v || "First name is required!"],
        lnameRule: [(v) => !!v || "Last name is required!"],
        emailRule: [(v) => !v || /.+@.+/.test(v) || "E-mail must be valid"],
        usernameRule: [(v) => !!v || "Username is required!"],
        dobRule: [
          (v) => {
            if (v) {
              let dateString = v.split("-");
              dateString.map((dat, i) => {
                if (i === 0) {
                }
              });
              return true;
            }
            return true;
          },
        ],
        primaryMobileRule: [
          (v) => !v || v.length === 10 || "Phone must be 10 digits",
        ],
        passwordRule: [
          (v) => !!v || "Password is required",
          (v) =>
            !(v && v.length < 8) || "Password must be atleast  8 characters",
        ],
        genderRule: [(v) => !!v || "Gender is required!"],
      },
      addressInfo: {
        data: {
          permanent_state_id: "",
          permanent_city_id: "",
          permanent_district_id: "",
          permanent_address_line: "",
          permanent_ward_no: "",
          temporary_state_id: "",
          temporary_city_id: "",
          temporary_district_id: "",
          temporary_address_line: "",
          temporary_ward_no: "",
        },
        validation: {
          valid: false,
          // permanent_state_id_rule: [
          //     (v) => !!v || 'Please select state!'
          // ],
          // temporary_state_id_rule: [
          //     (v) => !!v || 'Please select state!'
          // ],
          // permanent_ward_no: [
          //     (v) => !!v || 'Ward no required!'
          // ],
          // role: [
          //     (v) => !!v || 'You must specify a role!'
          // ],
          // permanent_address_line_rule: [
          //     (v) => !!v || 'Address is required!'
          // ],
          //
          // temporary_address_line_rule: [
          //     (v) => !!v || 'Address is required!'
          // ],
          // permanent_district_id_rule: [
          //     (v) => !!v || 'Please select district!'
          // ],
          // temporary_district_id_rule: [
          //     (v) => !!v || 'Please select district!'
          // ],
          // permanent_city_id_rule: [
          //     (v) => !!v || 'Please select municipality/city!'
          // ],
          // temporary_city_id_rule: [
          //     (v) => !!v || 'Please select municipality/city!'
          // ],
          // temporary_ward_no: [
          //     (v) => !!v || 'Address is required!'
          // ],
        },
      },
    },
    search: "",
    statesData,
    districtDataData,
    cityData,
    headers: [
      { text: "#", align: "center", value: "id", width: 20, sortable: false },
      { text: "Name", align: "left", value: "fname", width: 300 },
      {
        text: "Contact",
        align: "left",
        value: "contact",
        sortable: false,
        width: 300,
      },
      { text: "Associated at", align: "left", value: "created_at", width: 200 },
      { text: "Children", align: "left", value: "", width: 400 },
      { text: "Action", align: "right", sortable: false, width: 260 },
    ],
    updateState: false,
    filter: false,
    chartData: [],
    chartOptions: {
      title: {
        text: "Grade-Wise Students",
        align: "center",
      },
      legend: {
        show: false,
        floating: true,
      },
      labels: [],
    },
    blood: ["AB+", "AB-", "A+", "A-", "B+", "B-", "O+", "O-"],
    religions: ["Hindu", "Muslim", "Buddhist", "Christian", "Other"],
    occupations: [
      "Agriculture",
      "Businessman",
      "Driving",
      "Engineer",
      "Foreign Employment",
      "Government Service",
      "Health Services",
      "Housewife",
      "Teacher",
      "Software Developer",
      "Other",
    ],
    select: "",
    items: ["Programming", "Design", "Vue", "Vuetify"],
    children_no: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    same_address: false,
    p_show: false,
    states: [],
    permanent_districts: [],
    permanent_cities: [],
    temporary_districts: [],
    temporary_cities: [],
    ward_count_permanent: [],
    ward_count_temporary: [],
    ethnicities: [
      "Brahmin",
      "Chhetri",
      "Janajati",
      "Dalit",
      "Muslim",
      "Newari",
      "Madhesi",
      "Other",
    ],
    gender: ["Male", "Female", "Others"],
    roles: [],
    guardianRole: {},
    selectedRole: [],
    imgURL: "",
    resultURL: "",
    downloadColumns: [
      { value: "name", name: "Guardian Name", selected: true },
      { value: "gender", name: "Gender", selected: false },
      { value: "mobile", name: "Contact No.", selected: false },
      { value: "children", name: "Children Name", selected: true },
      { value: "email", name: "Email", selected: true },
      { value: "occupation", name: "Occupation", selected: true },
      { value: "dob", name: "Date Of Birth" },
    ],
    selected_no_of_children: "0",
  }),
  watch: {
    pagination: function () {
      this.get();
    },
    "form.fname": {
      handler(name) {
        this.form.fname = this.capitalizeString(name);
      },
    },
    "form.mname": {
      handler(name) {
        this.form.mname = this.capitalizeString(name);
      },
    },
    "form.lname": {
      handler(name) {
        this.form.lname = this.capitalizeString(name);
      },
    },
    "staff.addressInfo.data.permanent_address_line": {
      handler(name) {
        this.staff.addressInfo.data.permanent_address_line =
          this.capitalizeString(name);
      },
    },

    "staff.addressInfo.data.temporary_address_line": {
      handler(name) {
        this.staff.addressInfo.data.temporary_address_line =
          this.capitalizeString(name);
      },
    },
    "staff.addressInfo.data.permanent_state_id": {
      handler(v) {
        this.getDistricts("permanent", v);
      },
    },
    "staff.addressInfo.data.permanent_district_id": {
      handler(v) {
        this.getCities("permanent", v);
      },
    },
    "staff.addressInfo.data.permanent_city_id": {
      handler(v) {
        this.getWardCount("permament");
      },
    },
    "staff.addressInfo.data.temporary_state_id": {
      handler(v) {
        this.getTemporaryDistricts("temporary", v);
      },
    },
    "staff.addressInfo.data.temporary_district_id": {
      handler(v) {
        this.getTemporaryCities("temporary", v);
      },
    },
    "staff.addressInfo.data.temporary_city_id": {
      handler(v) {
        this.getWardCount("temporary");
      },
    },
    same_address: {
      handler(v) {
        this.copyAddress(v);
      },
    },
    search(val) {
      if (!val) return this.get();

      if (val.length < 1) return;

      // Items have already been requested
      if (this.loading) return;

      let $this = this;
      clearTimeout(typingTimer);
      typingTimer = setTimeout(function() {
        $this.get();
      }, doneTypingInterval);
    },
  },
  mounted() {
    this.getStates();
  },
  computed: {
    dynamicColumns() {
      let columns = [];
      this.downloadColumns.map((item) => {
        columns.push(item);
      });
      return columns;
    },
  },
  methods: {
    validate() {
      if (this.$refs.staffInfoForm.validate()) {
        this.save();
      }
    },
    searchMember: function (v) {
      if (v.length > 2 || !v.length) this.get();
    },
    cropImage(data) {
      this.cropperImg.cropImg = data.image;
      this.cropperImg.uploadCropped = data.cropped;
      return;

      // get image data for post processing, e.g. upload or setting image src
      const canvas = this.$refs.cropper.getCroppedCanvas();
      this.cropperImg.cropImg = canvas.toDataURL();
      let vm = this;
      canvas.toBlob(function (blob) {
        const imageCompressor = new ImageCompressor();
        imageCompressor.compress(blob, { quality: 0.3 }).then((result) => {
          result.name = "image";
          vm.cropperImg.uploadCropped = result;
          vm.cropperImg.cropDialog = false;
        });
      }, "image/jpeg");
    },
    getWardCount(type) {
      let $this = this;
      let totalWards = [];
      if (type === "permament") {
        let city = this.cityData.filter(function (city, key) {
          return $this.staff.addressInfo.data.permanent_city_id === city.id;
        });
        this.ward_count_permanent = [];
        // this.staff.addressInfo.data.permanent_ward_no = '';
        if (city.length) {
          city = city[0];
          let totalNumberOfWards = parseInt(city.ward_count);
          for (let i = 1; i <= totalNumberOfWards; i++) {
            totalWards.push(i);
          }
        }
        this.ward_count_permanent = totalWards;
      } else {
        let city = this.cityData.filter(function (city, key) {
          return $this.staff.addressInfo.data.temporary_city_id === city.id;
        });
        this.ward_count_temporary = [];
        // this.staff.addressInfo.data.temporary_ward_no = '';
        if (city.length) {
          city = city[0];
          let totalNumberOfWards = parseInt(city.ward_count);
          for (let i = 1; i <= totalNumberOfWards; i++) {
            totalWards.push(i);
          }
        }
        this.ward_count_temporary = totalWards;
      }
    },

    get(params) {
      let extraParams =
        "withChildren=true&type=guardian" + "&search=" + this.search.toString();
      let query = [null, undefined].includes(params)
        ? this.queryString(extraParams)
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.guardianRole = data.role;
        this.pagination.totalItems = data.meta.total;
      });
    },
    save() {
      if (this.$refs.staffInfoForm.validate()) {
        const { data } = this.staff;
        if (this.updateState === true) {
          this.form.roleId = getPropertyFromArrayObject(
            "id",
            this.selectedRole
          ).join(",");
        }
        // this.form.dob = this.form.dob.dateForm();
        this.form.pcity_id =
          this.staff.addressInfo.data.permanent_city_id || "";
        this.form.tcity_id =
          this.staff.addressInfo.data.temporary_city_id || "";
        this.form.pstreet =
          this.staff.addressInfo.data.permanent_address_line || "";
        this.form.tstreet =
          this.staff.addressInfo.data.temporary_address_line || "";
        this.form.pward_no =
          this.staff.addressInfo.data.permanent_ward_no || "";
        this.form.tward_no =
          this.staff.addressInfo.data.temporary_ward_no || "";
        this.form.pdistrict_id =
          this.staff.addressInfo.data.permanent_district_id || "";
        this.form.pstate_id =
          this.staff.addressInfo.data.permanent_state_id || "";
        this.form.tdistrict_id =
          this.staff.addressInfo.data.temporary_district_id || "";
        this.form.tstate_id =
          this.staff.addressInfo.data.temporary_state_id || "";

        if (!this.form.email) this.form.email = "";
        if (!this.form.primary_mobile) this.form.primary_mobile = "";

        //Check if a file was choosen.
        if (Object.keys(this.cropperImg.uploadCropped).length > 0) {
          this.form.image = this.cropperImg.uploadCropped;
        } else {
          delete this.form.image;
        }
        this.updateState
          ? this.form.put(this.form.id).catch((error) => {
            if(error.data.errors.primary_mobile[0]) {
              this.$events.fire("notification", {
                message: error.data.errors.primary_mobile[0],
                status: "error",
              });
            }else{
              this.$events.fire("notification", {
                message: error.data.message,
                status: "error",
              });
            }

          })
          : this.form.store().then((res) => {
              this.resetForm();
            })
      } else {
        this.$events.fire("notification", {
          message: "Validation Failed",
          status: "error",
        });
      }
    },
    getStates(type) {
      this.states = this.statesData.map((item) => {
        return { text: item.name, value: item.id };
      });
    },
    getDistricts(type, id) {
      this.permanent_districts = this.districtDataData
        .filter(
          (item) =>
            item.state_id === this.staff.addressInfo.data.permanent_state_id
        )
        .map((item) => {
          return { value: item.id, text: item.name };
        });
      this.permanent_cities = [];

      if (type === "permanent") {
      }
    },
    getTemporaryDistricts(type, id) {
      this.temporary_districts = this.districtDataData
        .filter(
          (item) =>
            item.state_id === this.staff.addressInfo.data.temporary_state_id
        )
        .map((item) => {
          return { value: item.id, text: item.name };
        });
    },
    getCities(type, id) {
      this.permanent_cities = this.cityData
        .filter(
          (item) =>
            item.district_id ===
            this.staff.addressInfo.data.permanent_district_id
        )
        .map((item) => {
          return { value: item.id, text: item.name };
        });
      if (type === "permanent") {
        // this.staff.addressInfo.data.permanent_city_id = '';
        // this.staff.addressInfo.data.permanent_address_line = '';
      }
      this.temporary_districts = this.permanent_districts;
      this.temporary_cities = this.permanent_cities;
    },
    getTemporaryCities(type, id) {
      this.temporary_cities = this.cityData
        .filter(
          (item) =>
            item.district_id ===
            this.staff.addressInfo.data.temporary_district_id
        )
        .map((item) => {
          return { value: item.id, text: item.name };
        });
    },
    fetchRoles() {
      this.$rest
        .get("/api/roles?rowsPerPage=50&sortBy=name")
        .then(({ data }) => {
          if (data.data.length) {
            this.roles = data.data;
          }
        });
    },
    copyAddress(status) {
      if (status) {
        if (
          this.staff.addressInfo.data.permanent_address_line &&
          this.staff.addressInfo.data.permanent_city_id
        ) {
          let temp = {
            state: this.staff.addressInfo.data.permanent_state_id,
            district: this.staff.addressInfo.data.permanent_district_id,
            city: this.staff.addressInfo.data.permanent_city_id,
            address: this.staff.addressInfo.data.permanent_address_line,
            ward_no: this.staff.addressInfo.data.permanent_ward_no,
          };
          this.ward_count_temporary = this.ward_count_permanent;
          let data = JSON.parse(JSON.stringify(temp));
          this.$set(
            this.staff.addressInfo.data,
            "temporary_state_id",
            data.state
          );
          this.$set(
            this.staff.addressInfo.data,
            "temporary_district_id",
            data.district
          );
          this.$set(
            this.staff.addressInfo.data,
            "temporary_city_id",
            data.city
          );
          this.$set(
            this.staff.addressInfo.data,
            "temporary_address_line",
            data.address
          );
          this.$set(
            this.staff.addressInfo.data,
            "temporary_ward_no",
            data.ward_no
          );
        }
      }
    },
    onFilePicked(e) {
      const file = e.target.files[0];
      if (!file.type.includes("image/")) {
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropperImg.imgSrc = event.target.result;
          this.cropperImg.name = file.name;

          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
          this.cropperImg.cropDialog = true;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    resetImagePicker() {
      this.imagePicker = {
        url: undefined,
        image: undefined,
        name: undefined,
      };
      document.querySelector("#image_picker_input").value = "";
      this.cropperImg.cropImg = "";
      this.cropperImg.name = "";
      // console.log(document.querySelector("#image_picker_input").value, "here ");
      $("#imagePickerText").val("");
    },

    resetForm() {
      this.staff.addressInfo.data.temporary_state_id = "";
      this.staff.addressInfo.data.temporary_address_line = "";
      this.staff.addressInfo.data.temporary_district_id = "";
      this.staff.addressInfo.data.temporary_city_id = "";
      this.staff.addressInfo.data.temporary_ward_no = "";
      this.staff.addressInfo.data.permanent_ward_no = "";
      this.staff.addressInfo.data.permanent_city_id = "";
      this.staff.addressInfo.data.permanent_district_id = "";
      this.staff.addressInfo.data.permanent_state_id = "";
      this.staff.addressInfo.data.permanent_address_line = "";
      this.cropperImg.cropImg = "";
      this.cropperImg.name = "";
      this.cropperImg.uploadCropped = "";
      if (this.$refs.staffInfoForm) this.$refs.staffInfoForm.reset();
      this.updateState = false;
      // document.querySelector("#image_picker_input").value = "";
    },
    /**
     * Image Upload Handler
     */
    onPickFile() {
      this.$refs.image.click();
    },
    update(items) {
      this.form.edit(items);
      this.updateState = true;
      const $this = this;
      this.same_address = false;
      Object.keys(items).map(function (item) {
        if (item === "roles") {
          $this.selectedRole = items[item];
        } else if (item === "tstate_id") {
          $this.staff.addressInfo.data.temporary_state_id = items[item];
        } else if (item === "tdistrict_id") {
          $this.staff.addressInfo.data.temporary_district_id = parseInt(
            items[item]
          );
        } else if (item === "tcity_id") {
          $this.staff.addressInfo.data.temporary_city_id = parseInt(
            items[item]
          );
        } else if (item === "tward_no") {
          $this.staff.addressInfo.data.temporary_ward_no = parseInt(
            items[item]
          );
        } else if (item === "tward_no") {
          $this.staff.addressInfo.data.temporary_ward_no = parseInt(
            items[item]
          );
        } else if (item === "tstreet") {
          $this.staff.addressInfo.data.temporary_address_line = items[item];
        } else if (item === "pstate_id") {
          $this.staff.addressInfo.data.permanent_state_id = items[item];
        } else if (item === "pdistrict_id") {
          $this.staff.addressInfo.data.permanent_district_id = parseInt(
            items[item]
          );
        } else if (item === "pcity_id") {
          $this.staff.addressInfo.data.permanent_city_id = parseInt(
            items[item]
          );
        } else if (item === "pward_no") {
          $this.staff.addressInfo.data.permanent_ward_no = parseInt(
            items[item]
          );
        } else if (item === "pstreet") {
          $this.staff.addressInfo.data.permanent_address_line = items[item];
        } else if (item === "image") {
          $this.cropperImg.cropImg = items[item];
        }
      });
    },
    capitalizeString(string) {
      if (string === undefined || string === null) return "";
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    downloadGuardian({ type, columns, columnString }) {
      // download/guardian/list
      let queryString = "column="+columnString +"&children_count=" +this.selected_no_of_children;
      
      redirectDownloadUrl({
            uri: "/download/guardian/list",
            queryString:queryString,
      });
      // this.$rest.get("/api/download/guardian").then(({ data }) => {
      //   console.log(data.data.download_url)
      //   let url =
      //     data.data.download_url +
      //     "?column=" +
      //     columnString +
      //     "&children_count=" +
      //     this.selected_no_of_children;
      //   window.open(url);
      // });
    },
  },
};
</script>

<style lang="scss">
.user_add_form {
  .v-chip,
  .v-chip .v-chip__content {
    border-radius: 2px;
    color: white;
  }
}

.v-timeline--dense:before {
  left: 18px !important;
}

.v-timeline-item__dot.v-timeline-item__dot--small {
  margin-left: 0px !important;
}

.first-f {
  background-color: #f44336 !important;
  border-color: #f44336 !important;
}

.second-s {
  background-color: rgba(255, 28, 26, 0.59) !important;
  border-color: rgba(255, 28, 26, 0.59) !important;
}

.third-t {
  background-color: yellow !important;
  border-color: yellow !important;
}

.fourth-f {
  background-color: #e48d0d !important;
  border-color: #e48d0d !important;
}

.fifth-fi {
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
}
</style>
